@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: sans-serif;
  @apply bg-black;
  @apply text-white;
}

.font-1 {
  font-family: sans-serif;
}

.font-2 {
  font-family: sans-serif;
}

code {
  font-family: monospace;
}

.btn {
  @apply font-2;
  @apply font-bold;
  @apply p-1;
  @apply px-2;
  @apply rounded-full;
  @apply justify-center;
  @apply text-center;
  @apply bg-green-600;
  @apply text-white;
  @apply disabled:text-gray-800;
  @apply disabled:bg-gray-600;
}

.input {
  @apply p-1;
  @apply rounded-md;
  @apply bg-green-600;
  @apply text-white;
  @apply disabled:text-gray-800;
  @apply disabled:bg-gray-600;
  /* appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield; */
}

.label {
  @apply font-2;
}

.value {
  @apply font-2;
}

.bg-animate-barberpole {
  background-size: 30px 30px;
  background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 75%,
    transparent 75%,
    transparent
  );
  animation: barberpole 1s linear infinite;
}

@keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 30px 30px;
  }
}

:root {
  --w3m-accent: #16a34a !important;
}

wui-loading-spinner {
  --local-color: var(--wui-color-accent-100);
}